import React, { Fragment } from "react";
import { Admin, Resource } from "react-admin";
import { i18nProvider } from "./i18n";

import { authProvider } from "./providers/auth";
import { client } from "./providers/client";
import { restProvider } from "./providers/rest";

import Dashboard from "./dashboard";
import { getResources } from "./resources";
import { useTheme } from "./theme";
import { LoginPage } from "./login";
import { history } from "./history";
import { MyLayout } from "./layout";
import { customRoutes } from "./routes";
import { Support } from "./support";
import { TITLE } from "./const";

const dataProvider = restProvider(client);

const initialState = {
  admin: { ui: { sidebarOpen: false, viewVersion: 0 } },
};

const App = () => {
  const theme = useTheme();

  return (
    <Fragment>
      <Admin
        theme={theme}
        title={TITLE}
        initialState={initialState}
        authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
        loginPage={LoginPage}
        i18nProvider={i18nProvider}
        history={history}
        layout={MyLayout}
        customRoutes={customRoutes}
      >
        {(user) =>
          getResources(user).map((resource) => <Resource {...resource} />)
        }
      </Admin>
      <Support />
    </Fragment>
  );
};

export default App;

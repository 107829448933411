export const diagnosis = [
  "MTB",
  "MTB Trace",
  "RIF Resistance",
  "SARS-CoV-2",
  "HPV 16"
].map(toDropdownOption);

export const sources = ["genexpert", "truenat", "pcr"].map(toDropdownOption);

export const detectedValues = [
  "DETECTED HIGH",
  "DETECTED MEDIUM",
  "DETECTED LOW",
  "DETECTED",
  "DETECTED VERY LOW",
  "POSITIVE"
];

export const presumptiveValues = ["PRESUMPTIVE POS"];

export const nonDetectedValues = ["NOT DETECTED", "NEGATIVE"];

export const errorValues = ["ERROR", "INVALID", "NO RESULT"];

export const qualitativeValues = [
  ...detectedValues,
  ...nonDetectedValues,
  ...presumptiveValues,
  ...errorValues
].map(toDropdownOption);

function toDropdownOption(id) {
  return {
    id,
    name: id
  };
}

export const TITLE = process.env.REACT_APP_TITLE || "Surveillance Portal";